<template>
  <DxScrollView
    v-if="beforeReq"
    height="100%"
    width="100%"
    class="with-footer single-card"
  >
    <div class="dx-card content">
      <div class="header">
        <img class="logo" :src="require('@/assets/ci.png')" />
      </div>
      <div class="notice">
        <b>환자 약물 부작용 정보 열람</b>
        <br /><br />
        <div class="text"></div>
      </div>

      <div class="passKeyContainer">
        <div class="text">
          생년월일 6자리를 입력하여 본인 확인 후 약물 부작용 정보 열람이
          가능합니다.(예 : 770112)
        </div>
        <br />
        <br />
        <DxTextBox width="100%" v-model:value="birthDay" styling-mode="filled">
        </DxTextBox>
      </div>

      <div class="reqBtn">
        <DxButton
          class="btn"
          text="생년월일 인증"
          @click="reqCert"
          styling-mode="outlined"
        />
      </div>
    </div>
  </DxScrollView>

  <DxScrollView
    v-if="incorrectUrl"
    height="100%"
    width="100%"
    class="with-footer single-card"
  >
    <div class="dx-card content">
      <div class="header">
        <img class="logo" :src="require('@/assets/ci.png')" />
      </div>
      <div class="notice">
        <b>잘못된 접근 입니다.</b>
        <br /><br />
        <div class="text">URL 확인 후 다시 시도해 주세요.</div>
        <br /><br />
      </div>
    </div>
  </DxScrollView>

  <div v-if="showADRData" class="pdfContainer">
    <div class="btnContainer">
      <DxButton
        class="btn"
        :width="165"
        text="FHIR"
        type="normal"
        styling-mode="contained"
        icon="download"
        @click="downloadFhir($event)"
      />
      <DxButton
        class="btn"
        :width="150"
        text="PDF"
        type="normal"
        styling-mode="contained"
        icon="download"
        @click="downloadPDF($event)"
      />
      <DxButton
        v-if="!isPreviewMode"
        class="btn"
        :width="150"
        text="URL"
        type="normal"
        styling-mode="contained"
        icon="copy"
        @click="copyURL($event)"
      />
    </div>

    <div v-for="page in pdfPages" :key="page">
      <VuePDF :pdf="pdfData" :page="page" fit-parent />
    </div>
  </div>
</template>

<script>
import DxScrollView from 'devextreme-vue/scroll-view';
import DxButton from 'devextreme-vue/button';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { VuePDF, usePDF } from '@tato30/vue-pdf';
import dsu from '@/utils/dataSourceUtils';
import fn from '@/utils/function';
import { DxTextBox } from 'devextreme-vue/text-box';
import axios from 'axios';

export default {
  components: {
    DxScrollView,
    DxButton,
    VuePDF,
    DxTextBox,
  },
  props: {
    isPreviewMode: Boolean,
  },
  data() {
    return {
      url: '',
      beforeReq: false,
      incorrectUrl: false,
      showADRData: false,
      selectedImage: null,
      selectedPtUrl: '',
      pdfDoc: undefined,
      adrData: {},
      adrDataD1: [],
      pdfData: undefined,
      pdfPages: undefined,
      birthDay: '',
      totalTime: 0,
      remainTime: '',
      timer: undefined,
    };
  },
  beforeMount() {
    this.url = this.$route.query.c;
    if (!this.isPreviewMode) {
      if (this.url) {
        var params = { url: this.url, exHst: 'Y' };
        dsu.getAxiosOBJPost('/api/selectADRDataPublic', params).then((res) => {
          let response = res.data;
          if (response.result == 'SUCCESS') {
            this.changeStatus('SHOW_ADR_DATA');
          } else {
            if (response.data == 'BadURL') {
              this.changeStatus('INCORRECT_URL');
            } else {
              this.changeStatus('BEFORE_REQ');
            }
          }
        });
      } else {
        this.changeStatus('INCORRECT_URL');
        return;
      }
    } else {
      this.changeStatus('SHOW_ADR_DATA');
    }
  },
  methods: {
    changeStatus(status) {
      if (status == 'BEFORE_REQ') {
        this.beforeReq = true;
        this.incorrectUrl = false;
        this.showADRData = false;
      } else if (status == 'INCORRECT_URL') {
        this.beforeReq = false;
        this.incorrectUrl = true;
        this.showADRData = false;
      } else if (status == 'SHOW_ADR_DATA') {
        this.beforeReq = false;
        this.incorrectUrl = false;
        this.showADRData = true;
        if (this.isPreviewMode) {
          this.getADRDataPrivate();
        } else {
          this.getADRData();
        }
      } else if (status == 'CLEAR') {
        this.beforeReq = false;
        this.incorrectUrl = false;
        this.showADRData = false;
      }
    },
    getADRDataPrivate() {
      var params = { url: this.selectedPtUrl };
      if (params.url) {
        dsu.getAxiosOBJGet('/api/MRPM/selectADRData', params).then((res) => {
          let response = res.data;
          if (response.result == 'SUCCESS') {
            let adrData = response.data[0];
            this.adrData = adrData;
            dsu
              .getAxiosOBJGet('/api/MRPM/selectADRDataD1', params)
              .then((res) => {
                let response = res.data;
                if (response.result == 'SUCCESS') {
                  let adrDataD1 = response.data;
                  this.adrDataD1 = adrDataD1;
                  this.generatePDF();
                } else {
                  fn.alert(response.msg, '확인').then(() => {
                    location.reload();
                  });
                }
              });
          } else {
            fn.alert(response.msg, '확인').then(() => {
              location.reload();
            });
          }
        });
      }
    },
    getADRData() {
      var params = { url: this.url };
      dsu.getAxiosOBJPost('/api/selectADRDataPublic', params).then((res) => {
        let response = res.data;
        if (response.result == 'SUCCESS') {
          let adrData = response.data;
          this.adrData = adrData;
          dsu
            .getAxiosOBJPost('/api/selectADRDataD1Public', params)
            .then((res) => {
              let response = res.data;
              if (response.result == 'SUCCESS') {
                let adrDataD1 = response.data;
                this.adrDataD1 = adrDataD1;
                this.generatePDF();
              } else {
                fn.alert(response.msg, '확인').then(() => {
                  location.reload();
                });
              }
            });
        } else {
          fn.alert(response.msg, '확인').then(() => {
            location.reload();
          });
        }
      });
    },
    async downloadFhir(e) {
      try {
        let reqUrl = '';
        var params = { url: '' };
        if (this.isPreviewMode) {
          reqUrl = '/api/downloadFhirPrivate';
          params.url = this.selectedPtUrl;
        } else {
          reqUrl = '/api/downloadFhirPublic';
          params.url = this.url;
        }

        e.component.option('disabled', true);

        dsu.setDefaultParams(params);
        const response = await axios({
          url: reqUrl, // 파일 URL을 여기에 넣으세요
          method: 'GET',
          params: params,
          headers: dsu.getHeaders(),
          responseType: 'blob', // 파일 데이터를 Blob 형식으로 받음
        });
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = decodeURIComponent(response.headers['filename']); // 다운로드할 파일명을 여기에 넣으세요
        link.click();
      } catch (e) {
        fn.notifyError('파일 다운로드 실패');
      }
      e.component.option('disabled', false);
    },
    refresh(url) {
      this.selectedPtUrl = url;
      this.changeStatus('SHOW_ADR_DATA');
    },
    clear() {
      this.pdfData = undefined;
      this.changeStatus('CLEAR');
    },
    reqCert(e) {
      if (!this.url) {
        this.changeStatus('INCORRECT_URL');
        return;
      }

      if (!this.birthDay) {
        fn.alert('생년월일을 입력해주세요.', '확인');
        return;
      }

      e.component.option('disabled', true);

      var params = {};
      params.url = this.url;
      params.birthDay = this.birthDay;

      dsu.getAxiosOBJPost('/uri2', params).then((res) => {
        let response = res.data;
        e.component.option('disabled', false);
        if (response.result == 'SUCCESS') {
          this.changeStatus('SHOW_ADR_DATA');
        } else {
          fn.alert(response.msg, '확인');
        }
      });
    },
    copyURL() {
      window.navigator.clipboard
        .writeText('https://myadr.care/p?c=' + this.url)
        .then(() => {
          fn.notifySuccess('복사 완료');
        });
    },
    makeTable(doc, subject, data) {
      doc.autoTable({
        theme: 'grid',
        styles: { halign: 'left', fontSize: 8, font: 'malgun' },
        rowPageBreak: 'auto',
        head: [
          [
            {
              content: subject,
              colSpan: 4,
              styles: { halign: 'left', fillColor: 199, textColor: 0 },
            },
          ],
        ],
        columns: [
          { dataKey: 'd1' },
          { dataKey: 'd2' },
          { dataKey: 'd3' },
          { dataKey: 'd4' },
        ],
        columnStyles: {
          d1: {
            fillColor: 255,
            textColor: 0,
            fontStyle: 'bold',
            cellWidth: 85,
          },
          d2: { cellWidth: 172 },
          d3: {
            fillColor: 255,
            textColor: 0,
            fontStyle: 'bold',
            cellWidth: 85,
          },
          d4: { cellWidth: 172 },
        },
        body: JSON.parse(JSON.stringify(data)),
        showHead: 'firstPage',
      });
    },
    convDrugInfo(drugInfo) {
      var rtnValue = '';
      if (drugInfo) {
        // rtnValue = JSON.parse(drugInfo).map(row=>('[' + row.drugType + ', ' + row.drugCode + '] ' + row.drugName)).join('\n');
        rtnValue = JSON.parse(drugInfo)
          .map((row) => row.drugName.split('(')[0])
          .join(', ');
      }
      return rtnValue;
    },
    convSymptomInfo(symptomInfo) {
      var rtnValue = '';
      if (symptomInfo) {
        // rtnValue = JSON.parse(symptomInfo).map(row=>('[' + row.symptomCode + '] ' + row.symptomNameKor + ' , ' + row.symptomNameEng)).join('\n');
        rtnValue = JSON.parse(symptomInfo)
          .map((row) => row.symptomNameKor)
          .join(', ');
      }
      return rtnValue;
    },
    generatePDF() {
      var doc = new jsPDF('p', 'pt', 'a4');
      doc.addFont(require('@/assets/malgun.ttf'), 'malgun', 'normal');
      doc.setFont('malgun');
      doc.autoTable({
        theme: 'grid',
        styles: { halign: 'center', fontSize: 12, font: 'malgun' },
        head: [
          [
            {
              content: '약물부작용 정보공유 서식지',
              styles: {
                halign: 'center',
                fillColor: [49, 100, 205],
                textColor: 255,
                fontStyle: 'bold',
              },
            },
          ],
        ],
      });
      //등록 희망자
      var data = [
        {
          d1: '성명',
          d2: this.adrData.ptName,
          d3: '병원등록번호',
          d4: this.adrData.ptNumber,
        },
        {
          d1: '생년월일',
          d2: this.adrData.ptRrn,
          d3: '휴대폰 번호',
          d4: this.adrData.ptTel,
        },
        {
          d1: '전자메일',
          d2: this.adrData.ptEmail,
          d3: '주소',
          d4: this.adrData.ptAddress,
        },
      ];
      this.makeTable(doc, '등록희망자', data);

      //약물이상사례(개별사례)
      for (let i = 0; i < this.adrDataD1.length; i++) {
        this.adrDataD1[i].d1CausativeDrug = this.convDrugInfo(
          this.adrDataD1[i].d1CausativeDrug
        );
        this.adrDataD1[i].d1Symptom = this.convSymptomInfo(
          this.adrDataD1[i].d1Symptom
        );
        data = [
          {
            d1: '원인약물',
            d2: {
              content: this.adrDataD1[i].d1CausativeDrug,
              styles: {
                textColor: 0,
                fontStyle: 'bold',
                fillColor: [234, 234, 234],
              },
            },
            d3: '발생일',
            d4: this.adrDataD1[i].d1OccurenceDate,
          },
          {
            d1: '증상',
            d2: this.adrDataD1[i].d1Symptom,
            d3: '인과성',
            d4: this.adrDataD1[i].d1Causality,
          },
          {
            d1: '사례_설명',
            d2: this.adrDataD1[i].d1CaseDescription,
            d3: '중증도',
            d4: this.adrDataD1[i].d1Severity,
          },
        ];

        let title = '';
        if (this.adrDataD1.length == 1) {
          title = '약물이상사례(개별사례)';
        } else {
          title = '약물이상사례(개별사례) - ' + (i + 1);
        }

        this.makeTable(doc, title, data);
      }

      //약물이상사례(통합조정)
      this.adrData.d2Symptom = this.convSymptomInfo(this.adrData.d2Symptom);
      this.adrData.d2CiGroup = this.convDrugInfo(this.adrData.d2CiGroup);
      this.adrData.d2CrhiGroup = this.convDrugInfo(this.adrData.d2CrhiGroup);
      this.adrData.d2OaiGroup = this.convDrugInfo(this.adrData.d2OaiGroup);
      data = [
        {
          d1: '현재상태',
          d2: this.adrData.d2CurrentStatus,
          d3: '증상',
          d4: this.adrData.d2Symptom,
        },
        {
          d1: {
            content: '금기 성분(군)',
            styles: { fontStyle: 'bold', fillColor: [241, 64, 45] },
          },
          d2: {
            content: this.adrData.d2CiGroup,
            styles: { fontStyle: 'bold', fillColor: [249, 202, 196] },
          },
          d3: '유형',
          d4: this.adrData.d2DrugSideEffectsType,
        },
        {
          d1: {
            content: '주의 성분(군)',
            styles: { fontStyle: 'bold', fillColor: [245, 194, 91] },
          },
          d2: {
            content: this.adrData.d2CrhiGroup,
            styles: { fontStyle: 'bold', fillColor: [252, 237, 205] },
          },
          d3: '인과성',
          d4: this.adrData.d2Causality,
        },
        {
          d1: {
            content: '복용가능 성분(군)',
            styles: { fontStyle: 'bold', fillColor: [98, 197, 92] },
          },
          d2: {
            content: this.adrData.d2OaiGroup,
            styles: { fontStyle: 'bold', fillColor: [214, 243, 212] },
          },
          d3: '중증도',
          d4: this.adrData.d2Severity,
        },
        {
          d1: '전문가의견',
          d2: this.adrData.d2ExpertOpinion,
          d3: '확진검사',
          d4: this.adrData.d2ConfTest,
        },
      ];
      this.makeTable(doc, '약물이상사례(통합조정)', data);

      //약물이상사례(통합조정)
      data = [
        {
          d1: '의료기관명',
          d2: this.adrData.d3HospitalName,
          d3: '의료기관코드',
          d4: this.adrData.d3HospitalCode,
        },
        {
          d1: '최종평가자 직역',
          d2: this.adrData.d3FinalEvaluator,
          d3: '최종평가자 전문과목',
          d4: this.adrData.d3MedicalDepartment,
        },
        {
          d1: '평가부서',
          d2: this.adrData.d3EvaluationDepartment,
          d3: '평가부서 전화번호',
          d4: this.adrData.d3ManagerTel,
        },
      ];
      this.makeTable(doc, '의료기관', data);

      //기타
      data = [
        {
          d1: '최초작성일',
          d2: this.adrData.d4InsertTime,
          d3: '서식수정일',
          d4: this.adrData.d4UpdateTime,
        },
      ];
      this.makeTable(doc, '기타', data);

      const { pdf, pages } = usePDF(
        doc.output('bloburl', { filename: '정보.pdf' })
      );
      this.pdfData = pdf;
      this.pdfPages = pages;
      this.pdfDoc = doc;
    },
    downloadPDF() {
      this.pdfDoc.save('약물부작용 정보.pdf');
    },
  },
};
</script>

<style lang="scss">
@import '../themes/generated/variables.base.scss';
.notice {
  font-size: 17px;
  text-align: left;

  .text {
    text-indent: 0.4em;
  }
  .passKeyContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.reqBtn {
  width: 100%;
  display: flex;
  margin-top: 35px;
  justify-content: center;
  .btn {
    width: 90%;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
  }
}

.single-card {
  width: 100%;
  height: 100%;

  .dx-card {
    width: 400px;
    margin: auto auto;
    padding: 40px;
    flex-grow: 0;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;
      text-align: center;

      .logo {
        width: 70%;
      }
    }
  }
}

.pdfContainer {
  width: 100%;
  text-align: center;
  .btnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    .btn {
      font-size: 16px;
      font-weight: bold;
      height: 45px;
      margin: 5px;
    }
  }
}
</style>
