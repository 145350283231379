<template>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :dataSource="dataSource"
      :columnAutoWidth="true"
      :showBorders="true"
      :showColumnLines="true"
      :allowColumnReordering="true"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      key-expre="recId"
      @toolbar-preparing="onToolbarPreparing($event)"
      @selection-changed="onSelectionChanged"
    >
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="virtual" />
      <DxSorting mode="multiple" />
      <DxSelection show-check-boxes-mode="always" mode="multiple" />

      <DxColumn
        data-field="recId"
        caption="Record ID"
        :header-filter="{ allowSearch: true }"
        :allowResizing="true"
      >
      </DxColumn>

      <DxColumn
        data-field="hospitalNameCode"
        caption="요양기관"
        :header-filter="{ allowSearch: true }"
        :allowResizing="true"
      >
      </DxColumn>

      <DxColumn
        data-field="transTime"
        caption="등록일자"
        :header-filter="{ allowSearch: true }"
        :allowResizing="true"
      >
      </DxColumn>

      <DxColumn
        data-field="d1CausativeDrug"
        caption="원인약물"
        :allowFiltering="false"
        :allowSorting="false"
        cell-template="drug-cell-template"
        :allowResizing="true"
      >
      </DxColumn>

      <DxColumn
        data-field="status"
        caption="상태"
        :header-filter="{ allowSearch: true }"
        :allowResizing="true"
      >
        <DxLookup
          :data-source="recStatus"
          value-expr="ID"
          display-expr="Name"
        />
      </DxColumn>

      <DxColumn
        data-field="d1Severity"
        caption="중증도"
        :header-filter="{ allowSearch: true }"
        :allowResizing="true"
      >
      </DxColumn>

      <DxColumn
        data-field="d2CiGroup"
        caption="금기 성분(군)"
        :allowFiltering="false"
        :allowSorting="false"
        cell-template="drug-cell-template"
        :allowResizing="true"
      >
      </DxColumn>

      <template #drug-cell-template="{ data }">
        <GridDrug :drugData="data.value" />
      </template>
    </DxDataGrid>

    <DxPopup
      :ref="popupRefName"
      height="calc(100vh * 0.9)"
      width="90%"
      :drag-enabled="false"
      :hideOnOutsideClick="true"
      :showTitle="false"
      @hidden="onHiddenPopup"
    >
      <DxTabs
        :refs="tabRefName"
        :data-source="[
          '상세보기(수정)',
          '수정 이력',
          '환자 조회 이력(URL)',
          '의약품 안전카드',
        ]"
        v-model:selected-index="selectedIndexTab"
      />
      <DxScrollView height="100%" class="sv">
        <div class="content-block dx-card responsive-paddings">
          <div v-if="enableAdrDataForm" class="adrFormContainerModify">
            <AdrDataForm
              class="dataView"
              :recId="selectedRecId"
              :isEditingMode="true"
              @refreshDataParent="refreshData"
            />
          </div>

          <AdrDataHistory
            v-if="enableAdrHistory"
            :masterDetailData="{ key: selectedRecId }"
          />

          <AdrDataSearchURL
            v-if="enablePTSearchHistory"
            :recId="selectedRecId"
            :recordData="selectedRecordData"
            :ptInfo="ptInfo.data"
          />

          <PtSearchHistory
            v-if="enablePTSearchHistory"
            :recId="selectedRecId"
          />
          <VForm v-if="enableVForm" :recId="selectedRecId" />
        </div>
        <div class="dummy"></div>
      </DxScrollView>
    </DxPopup>

    <DxPopup
      :ref="popupPreviewRefName"
      height="90%"
      width="90%"
      :drag-enabled="false"
      :hideOnOutsideClick="true"
      :showTitle="true"
      title="환자 약물 부작용 정보 미리보기"
      @hidden="clearIV"
    >
      <DxScrollView height="100%" class="sv">
        <IVForm :ref="ivRefName" :isPreviewMode="true" />
      </DxScrollView>
    </DxPopup>

    <DxPopup
      :ref="popupRegRefName"
      height="calc(100vh * 0.9)"
      width="90%"
      :drag-enabled="false"
      :hideOnOutsideClick="true"
      :showTitle="true"
      title="부작용 정보 등록"
    >
      <div class="content-block dx-card responsive-paddings">
        <div class="adrFormContainerNew">
          <AdrDataForm
            class="dataView"
            :ref="adrDataRegRefName"
            :ptNumber="ptInfo.data.ptNumber"
            @refreshDataParent="refreshData"
          />
        </div>
      </div>
    </DxPopup>
    <PrintSendCard :ref="printSendCardRefName" />
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxHeaderFilter,
  DxSearchPanel,
  DxLookup,
  DxSelection,
} from 'devextreme-vue/data-grid';
import DxTabs from 'devextreme-vue/tabs';
import DxPopup from 'devextreme-vue/popup';
import AdrDataForm from '../_common/ADR_DATA_FORM';
import AdrDataHistory from '../_common/ADR_DATA_HISTORY';
import PtSearchHistory from '../_common/PT_SEARCH_HISTORY';
import AdrDataSearchURL from '../_common/ADR_DATA_SEARCH_URL';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import dsu from '../../utils/dataSourceUtils';
import fn from '../../utils/function';
import { userStatus } from '../../utils/syscodedata';
import GridDrug from '../_common/GRID_DRUG';
import IVForm from '../iv2-form';
import VForm from '../v-form';
import PrintSendCard from '../_common/PRINT_SEND_CARD';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxHeaderFilter,
    DxSearchPanel,
    DxLookup,
    DxSelection,
    DxPopup,
    AdrDataForm,
    DxScrollView,
    DxTabs,
    AdrDataHistory,
    PtSearchHistory,
    AdrDataSearchURL,
    GridDrug,
    IVForm,
    VForm,
    PrintSendCard,
  },
  data() {
    return {
      dataSource: [],
      recStatus: userStatus,
      popupRefName: 'popup',
      tabRefName: 'tab',
      enableAdrDataForm: false,
      enableAdrHistory: false,
      enablePTSearchHistory: false,
      enableVForm: false,
      selectedIndexTab: -1,
      selectedRowData: [],
      selectedRecId: '',
      selectedRecordData: {},
      popupPreviewRefName: 'popupPreview',
      isEditingMode: true,
      popupRegRefName: 'popupReg',
      adrDataRegRefName: 'adrDataReg',
      ivRefName: 'iv',
      printSendCardRefName: 'printSendCard',
    };
  },
  props: {
    ptInfo: Object,
  },
  watch: {
    selectedIndexTab: function (newValue) {
      if (newValue == 0) {
        this.enableAdrDataForm = true;
        this.enableAdrHistory = false;
        this.enablePTSearchHistory = false;
        this.enableVForm = false;
      } else if (newValue == 1) {
        this.enableAdrDataForm = false;
        this.enableAdrHistory = true;
        this.enablePTSearchHistory = false;
        this.enableVForm = false;
      } else if (newValue == 2) {
        this.enableAdrDataForm = false;
        this.enableAdrHistory = false;
        this.enablePTSearchHistory = true;
        this.enableVForm = false;
      } else if (newValue == 3) {
        this.enableAdrDataForm = false;
        this.enableAdrHistory = false;
        this.enablePTSearchHistory = false;
        this.enableVForm = true;
      } else {
        this.enableAdrDataForm = false;
        this.enableAdrHistory = false;
        this.enablePTSearchHistory = false;
        this.enableVForm = false;
      }
    },
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            text: '신규 등록',
            icon: 'add',
            stylingMode: 'outlined',
            onClick: this.showReg.bind(this),
          },
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            text: '상세보기(수정)',
            icon: 'edit',
            stylingMode: 'outlined',
            onClick: this.modify.bind(this),
          },
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            text: '삭제',
            icon: 'remove',
            stylingMode: 'outlined',
            locateInMenu: 'auto',
            onClick: this.remove.bind(this),
          },
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            text: '미리보기',
            icon: 'image',
            stylingMode: 'outlined',
            locateInMenu: 'auto',
            onClick: this.preview.bind(this),
          },
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            text: '의약품 안전카드 인쇄/전송',
            icon: 'print',
            stylingMode: 'outlined',
            locateInMenu: 'auto',
            onClick: this.printSendCard.bind(this),
          },
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            text: '개인정보 활용 동의 요청',
            icon: 'message',
            stylingMode: 'outlined',
            locateInMenu: 'auto',
            onClick: this.requestAgreement.bind(this),
          },
        }
      );
    },
    refreshData() {
      dsu
        .getAxiosOBJGet('/api/MRPD/selectADRDataByPT', this.ptInfo.data)
        .then((res) => {
          let response = res.data;
          if (response.result == 'SUCCESS') {
            this.dataSource = response.data;
          } else {
            fn.notifyError(response.msg);
          }
        });
    },
    modify() {
      if (this.selectedRowData.length < 1) {
        fn.notifyError('수정 대상 선택 후 진행 필요');
        return;
      } else if (this.selectedRowData.length > 1) {
        fn.notifyError('하나의 수정 대상만 선택 후 진행 필요');
        return;
      }

      this.selectedRecId = this.selectedRowData[0].recId;
      this.selectedRecordData = JSON.parse(
        JSON.stringify(this.selectedRowData[0])
      );
      this.$refs[this.popupRefName].instance.show();
      this.selectedIndexTab = 0;
    },
    onHiddenPopup() {
      this.selectedIndexTab = -1;
    },
    onSelectionChanged(e) {
      this.selectedRowData = e.selectedRowsData;
    },
    preview() {
      if (this.selectedRowData.length < 1) {
        fn.notifyError('미리보기 대상 선택 후 진행 필요');
        return;
      } else if (this.selectedRowData.length > 1) {
        fn.notifyError('하나의 미리보기 대상만 선택 후 진행 필요');
        return;
      }
      this.$refs[this.popupPreviewRefName].instance.show();
      this.$refs[this.ivRefName].refresh(this.selectedRowData[0].url);
    },
    clearIV() {
      this.$refs[this.ivRefName].clear();
    },
    requestAgreement(e) {
      e.component.option('disabled', true);
      var params = this.ptInfo.data;

      dsu.getAxiosOBJPost('/api/MRPM/sendIVMsg', params).then((res) => {
        let response = res.data;
        if (response.result == 'SUCCESS') {
          fn.notifySuccess(response.msg);
        } else {
          fn.notifyError(response.msg);
        }
        e.component.option('disabled', false);
      });
    },
    showReg() {
      this.$refs[this.adrDataRegRefName].mount();
      this.$refs[this.popupRegRefName].instance.show();
    },
    printSendCard() {
      if (this.selectedRowData.length < 1) {
        fn.notifyError('인쇄/전송 대상 선택 후 진행 필요');
        return;
      }

      let targetRecId = this.selectedRowData
        .map((data) => data.recId)
        .toString();
      var params = {};
      params.printMethod = 'NORMAL';
      params.targetRecId = targetRecId;
      const printSendCard = this.$refs[this.printSendCardRefName];
      printSendCard.show(params);
    },
    async remove(e) {
      var params = {};
      if (this.selectedRowData.length < 1) {
        fn.notifyError('삭제 대상 선택 후 진행 필요');
        return;
      }

      if ((await fn.confirm('정말 삭제 하시겠습니까?')) == false) {
        return;
      }

      params.targetRecId = this.selectedRowData
        .map((data) => data.recId)
        .toString();
      e.component.option('disabled', true);

      dsu.getAxiosOBJPost('/api/MRPM/deleteADRData', params).then((res) => {
        let response = res.data;
        if (response.result == 'SUCCESS') {
          fn.notifySuccess(response.msg);
          this.refreshData();
        } else {
          fn.notifyError(response.msg);
        }
        e.component.option('disabled', false);
      });
    },
  },
};
</script>

<style scoped>
.dummy {
  height: 20px;
}

.dataView {
  margin-top: 15px;
}

.sv {
  background-color: rgb(240, 240, 240);
}

#grid {
  max-height: 500px;
}

.adrFormContainerNew {
  height: calc(100vh * 0.9 - 130px);
}

.adrFormContainerModify {
  height: calc(100vh * 0.9 - 110px);
}
</style>
