<template>
  <div class="content-block dx-card responsive-paddings">
    <div class="memoryItem">
      <div class="memoryType">Memory {{ memoryType }}</div>
      <div class="main-info-memory-item">
        <span class="model-name">{{ llmModel }}</span>
        <span class="temp-gen">Temp: {{ temp }}</span>
        <span class="response-time">{{ responseTime }}s</span>
        <span class="trans-time">{{ transTimeStr }}</span>
      </div>

      <DxButton
        @click="toggleVisibility"
        :icon="showMemoryIcon"
        styling-mode="text"
        class="visibleToggle"
      />
    </div>
    <div
      v-show="showMemory"
      v-for="(item, index) in dataSource"
      :key="index"
      class="memory"
    >
      <div v-if="item.type === 'SYSTEM'" class="system">
        <div class="type">
          {{ item.type }}
          <dx-button
            @click="copyToClipboard(item.text)"
            icon="copy"
            styling-mode="text"
            class="copy-button"
          ></dx-button>
        </div>
        <div class="text">{{ item.text }}</div>
      </div>
      <div v-if="item.type === 'USER'" class="user">
        <div class="type">
          {{ item.type }}
          <dx-button
            @click="copyToClipboard(item.contents[0].text)"
            icon="copy"
            styling-mode="text"
            class="copy-button"
          ></dx-button>
        </div>
        <div class="text">{{ item.contents[0].text }}</div>
      </div>
      <div v-if="item.type === 'AI'" class="ai">
        <div class="type">
          {{ item.type }}
          <dx-button
            @click="copyToClipboard(item.text)"
            icon="copy"
            styling-mode="text"
            class="copy-button"
          ></dx-button>
        </div>
        <div class="text">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import fn from '../../utils/function';

export default {
  components: {
    DxButton,
  },
  data() {
    return {
      dataSource: [],
      memoryType: '',
      llmModel: '',
      responseTime: null,
      temp: null,
      transTimeStr: '',
      showMemory: false,
      showMemoryIcon: 'chevrondown',
    };
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          fn.notifySuccess('복사되었습니다.');
        },
        () => {
          fn.notifyError('복사에 실패했습니다.');
        }
      );
    },
    setData(data, memoryType, llmModel, responseTime, temp, transTimeStr) {
      this.dataSource = JSON.parse(data);
      this.memoryType = memoryType;
      this.llmModel = llmModel;
      this.responseTime = responseTime;
      this.temp = temp;
      this.transTimeStr = transTimeStr;
      this.showMemory = false;
      this.showMemoryIcon = 'chevrondown';
    },
    toggleVisibility() {
      this.showMemory = !this.showMemory;
      if (this.showMemory) {
        this.showMemoryIcon = 'chevronup';
      } else {
        this.showMemoryIcon = 'chevrondown';
      }
    },
  },
};
</script>

<style lang="scss">
.memory {
  .system,
  .user,
  .ai {
    position: relative;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
    white-space: pre-wrap;
    text-align: left;
    .type {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .copy-button {
      position: absolute;
      top: 10px;
      right: 10px;
      font-weight: bold;
    }
  }

  .system {
    background-color: #e0e0e0;
    margin-right: 10%;
    margin-left: 20px;
  }
  .user {
    background-color: #cce5ff;
    margin-left: 10%;
    margin-right: 20px;
  }
  .ai {
    background-color: #d4edda;
    margin-left: 20px;
    margin-right: 10%;
  }
}

.memoryItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .memoryType {
    background-color: #e7e5e5;
    padding: 7px;
    border-radius: 5px;
    display: inline-block;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 16px;
  }

  .main-info-memory-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    margin-left: 20px;
    margin-top: 10px;
    background-color: white;

    .model-name {
      font-weight: bold;
      color: #2c3e50;
    }

    .temp-gen {
      font-size: 0.9em;
      color: #7f8c8d;
    }

    .response-time {
      font-size: 0.9em;
      color: #27ae60;
    }
    .trans-time {
      font-size: 0.9em;
      color: #7f8c8d;
    }
  }

  .visibleToggle {
    margin-left: auto;
  }
}
</style>
