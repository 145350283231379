<template>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      @rowDblClick="onRowDblClick"
      @row-removing="onRowRemoving"
    >
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="virtual" />
      <DxSorting mode="multiple" />
      <DxEditing
        :allow-updating="false"
        :allow-adding="false"
        :allow-deleting="true"
      />
      <DxColumn type="buttons" width="35">
        <DxButton name="edit" />
      </DxColumn>

      <DxColumn
        data-field="reqId"
        caption="Req ID"
        width="80"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="reqDate"
        caption="요청 일"
        width="90"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="status"
        caption="상태"
        width="85"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="useModels"
        caption="Models"
        Width="200"
        :header-filter="{ allowSearch: true }"
        cell-template="llm-models-cell-template"
      >
      </DxColumn>
      <DxColumn
        data-field="age"
        data-type="number"
        caption="나이"
        width="100"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="gender"
        caption="성별"
        width="70"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="comorbidities"
        caption="동반질환"
        minWidth="300"
        :header-filter="{ allowSearch: true }"
        cell-template="diagnosis-cell-template"
      />
      <DxColumn
        data-field="sideEffects"
        caption="부작용 정보(원인약물-증상)"
        minWidth="400"
        :header-filter="{ allowSearch: true }"
        cell-template="sideEffects-cell-template"
      />
      <DxColumn
        data-field="sideEffectsStr"
        caption="부작용 정보(Free Text)"
        minWidth="400"
        :header-filter="{ allowSearch: true }"
        cell-template="sideEffectsStr-cell-template"
      />
      <DxColumn
        data-field="currentMedication"
        caption="현재(=기) 복용 약제"
        minWidth="300"
        :header-filter="{ allowSearch: true }"
        cell-template="kd-cell-template"
      />
      <DxColumn
        data-field="newPrescriptions"
        caption="신규 처방 약제"
        minWidth="300"
        :header-filter="{ allowSearch: true }"
        cell-template="kd-cell-template"
      />

      <template #diagnosis-cell-template="{ data }">
        <GridDiagnosis :diagnosisData="data.value" />
      </template>
      <template #sideEffects-cell-template="{ data }">
        <GridSideEffects :sideEffectsData="data.value" />
      </template>
      <template #sideEffectsStr-cell-template="{ data }">
        <GridSideEffectsStr :sideEffectsData="data.value" />
      </template>
      <template #kd-cell-template="{ data }">
        <GridKd :kdData="data.value" />
      </template>

      <template #llm-models-cell-template="{ data }">
        <div v-for="(model, index) in data.value.split(',')" :key="index">
          {{ model }}
        </div>
      </template>
    </DxDataGrid>
    <DxPopup
      id="popup"
      :ref="popupRefName"
      height="95%"
      width="95%"
      :drag-enabled="false"
      :hideOnOutsideClick="true"
      :showTitle="false"
      :showCloseButton="true"
    >
      <DxTabs
        :refs="tabRefName"
        :data-source="[
          '환자 정보',
          '현재(=기) 복용 약제',
          '신규 처방 약제',
          '복약 지도문',
        ]"
        v-model:selected-index="selectedIndexTab"
      />
      <div class="tabContainer">
        <DxTextArea
          v-show="enableTab1"
          :readOnly="true"
          class="tabView"
          :value="selectedRow.patientInfoStr"
        />
        <DxTextArea
          v-show="enableTab2"
          :readOnly="true"
          class="tabView"
          :value="selectedRow.currentMedicationStr"
        />
        <DxTextArea
          v-show="enableTab3"
          :readOnly="true"
          class="tabView"
          :value="selectedRow.newPrescriptionsStr"
        />
        <MgMemoryHst
          v-show="enableTab4"
          :ref="mgMemoryHstRefName"
          class="tabView"
        />
      </div>
    </DxPopup>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxHeaderFilter,
  DxSearchPanel,
  DxEditing,
} from 'devextreme-vue/data-grid';
import DxPopup from 'devextreme-vue/popup';
import GridDiagnosis from '../_common/GRID_DIAGNOSIS';
import GridSideEffects from '../_common/GRID_SIDE_EFFECTS';
import GridSideEffectsStr from '../_common/GRID_SIDE_EFFECTS_STR';
import GridKd from '../_common/GRID_KD';
import dsu from '../../utils/dataSourceUtils';
import fn from '../../utils/function';
import DxTabs from 'devextreme-vue/tabs';
import DxTextArea from 'devextreme-vue/text-area';
import MgMemoryHst from '../_common/MG_MEMORY_HST';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxHeaderFilter,
    DxSearchPanel,
    DxEditing,
    DxPopup,
    GridDiagnosis,
    GridKd,
    GridSideEffects,
    DxTabs,
    DxTextArea,
    MgMemoryHst,
    GridSideEffectsStr,
  },
  data() {
    return {
      gridRefName: 'grid',
      popupRefName: 'popup',
      selectedRow: {},
      tabRefName: 'tab',
      selectedIndexTab: -1,
      mgMemoryHstRefName: 'mgMemoryHst',
    };
  },
  watch: {
    selectedIndexTab: function (newValue) {
      this.enableTab1 = false;
      this.enableTab2 = false;
      this.enableTab3 = false;
      this.enableTab4 = false;

      if (newValue == 0) {
        this.enableTab1 = true;
      } else if (newValue == 1) {
        this.enableTab2 = true;
      } else if (newValue == 2) {
        this.enableTab3 = true;
      } else if (newValue == 3) {
        this.enableTab4 = true;
      }
    },
  },
  mounted() {
    this.search();
  },
  computed: {
    grid: function () {
      return this.$refs[this.gridRefName].instance;
    },
    popup: function () {
      return this.$refs[this.popupRefName].instance;
    },
  },
  methods: {
    search() {
      this.popup.hide();
      this.grid.beginCustomLoading('데이터 조회 중...');
      dsu.getAxiosOBJGet('/api/MMMG/selectMGDataIF').then((res) => {
        this.grid.endCustomLoading();
        let response = res.data;
        if (response.result == 'SUCCESS') {
          var data = response.data;
          this.setGridData(data);
          this.$emit('refreshMenu');
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
    setGridData(data) {
      const grid = this.$refs[this.gridRefName].instance;
      grid.option('keyExpr', 'reqId');
      grid.option('dataSource', data);
    },
    onRowDblClick(e) {
      this.selectedRow = e.data;
      this.selectedIndexTab = -1;
      this.$refs[this.mgMemoryHstRefName].setMgMemoryHst(e.data.reqId);
      this.popup.show();
      this.selectedIndexTab = 3;
    },
    onRowRemoving(e) {
      e.cancel = true;
      dsu.getAxiosOBJPost('/api/MMMG/deleteMGDataIF', e.data).then((res) => {
        this.search();
        let response = res.data;
        if (response.result == 'SUCCESS') {
          fn.notifySuccess(response.msg);
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss">
#grid {
  max-height: calc(100vh - 210px);
}

.tabContainer {
  height: 100%;
  width: 100%;
  .tabView {
    height: calc(100% - 70px);
    margin: 10px;
  }
}

.dx-texteditor.dx-state-readonly .dx-texteditor-input {
  background-color: rgb(231, 229, 229); /* 배경색을 원래대로 */
  color: black !important; /* 텍스트 색상을 원래대로 */
  /* 필요에 따라 추가 스타일 적용 */
}
</style>
