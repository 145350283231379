<template>
  <div>
    <DxBox direction="row" width="100%" height="100%">
      <DxItem :visible="showMemoryHst" :ratio="0" :baseSize="600">
        <template #default>
          <div class="content-block dx-card responsive-paddings">
            <DxTreeList
              :ref="mgMemoryHstRefName"
              :show-row-lines="false"
              :show-column-lines="false"
              :focusedRowEnabled="true"
              :autoNavigateToFocusedRow="true"
              key-expr="seq"
              height="100%"
              parent-id-expr="fromSeq"
              @focusedRowChanged="setMgMemory"
            >
              <DxScrolling mode="both" />
              <DxSorting mode="none" />
              <DxSelection mode="none" />
              <DxColumn
                data-field="llmModelGen"
                caption="History"
                cell-template="contents-cell-template"
                headerCellTemplate="header-cell-template"
              />
              <template #header-cell-template="{ data }">
                <div class="hstTitleText">{{ data.column.caption }}</div>
              </template>
              <template #contents-cell-template="{ data }">
                <div class="cell-container">
                  <div class="main-info">
                    <span v-if="data.data.llmLatest == 'Y'" class="llm-latest">
                      Latest
                    </span>
                    <span class="model-name">{{ data.data.llmModelGen }}</span>
                    <span class="update-user">{{ data.data.updateUser }}</span>
                    <span class="trans-time">{{ data.data.transTimeStr }}</span>
                  </div>
                  <div
                    v-if="data.data.genType == 'Feedback'"
                    class="feedbackText"
                  >
                    <strong>Feedback:</strong> {{ data.data.feedback }}
                  </div>
                </div>
              </template>
            </DxTreeList>
          </div>
        </template>
      </DxItem>
      <DxItem :ratio="1">
        <template #default>
          <MgMemory
            :ref="mgMemoryRefName"
            @refreshData="setMgMemoryHst"
            @toggleVisibility="toggleVisibility"
            :showMemoryHst="showMemoryHst"
          />
        </template>
      </DxItem>
    </DxBox>
  </div>
</template>

<script>
import { DxBox, DxItem } from 'devextreme-vue/box';
import {
  DxTreeList,
  DxColumn,
  DxSelection,
  DxSorting,
  DxScrolling,
} from 'devextreme-vue/tree-list';
import MgMemory from '../_common/MG_MEMORY';
import dsu from '../../utils/dataSourceUtils';
import fn from '../../utils/function';

export default {
  components: {
    DxBox,
    DxItem,
    DxTreeList,
    DxColumn,
    DxSelection,
    DxSorting,
    MgMemory,
    DxScrolling,
  },
  data() {
    return {
      reqId: '',
      mgMemoryHstRefName: 'mgMemoryHst',
      mgMemoryRefName: 'mgMemory',
      showMemoryHst: false,
    };
  },
  computed: {
    mgMemoryHst: function () {
      return this.$refs[this.mgMemoryHstRefName].instance;
    },
    mgMemory: function () {
      return this.$refs[this.mgMemoryRefName];
    },
  },
  methods: {
    onCellPrepared(e) {
      if (e.rowType == 'header') {
        e.cellElement.bgColor = '#E4EEF7';
        e.cellElement.style.fontWeight = 'bold';
      } else if (e.rowType == 'data') {
        e.cellElement.bgColor = '#f5f5f5';
        e.cellElement.style.fontWeight = 'bold';
      }
    },
    setHstData(data) {
      this.mgMemoryHst.option('focusedRowKey', null);
      this.mgMemoryHst.option('dataSource', data);
      const filteredArray = data.filter((item) => item.llmLatest === 'Y');
      data.map((item) => {
        this.mgMemoryHst.expandRow(item.seq);
      });
      this.mgMemoryHst.option('focusedRowKey', filteredArray[0].seq);
    },
    setMgMemoryHst(reqId) {
      this.reqId = reqId;
      dsu
        .getAxiosOBJGet('/api/MMMG/selectMGDataMemoryHst', {
          reqId: this.reqId,
        })
        .then((res) => {
          let rtnMsg = res.data.msg;
          if (res.data.result == 'SUCCESS') {
            this.setHstData(res.data.data);
          } else {
            fn.notifyError(rtnMsg);
          }
        });
    },
    setMgMemory(e) {
      if (e.rowIndex > -1) {
        this.mgMemory.setData(e.row.data);
      }
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    toggleVisibility() {
      this.showMemoryHst = !this.showMemoryHst;
    },
  },
};
</script>

<style lang="scss">
.memoryHistRow {
  margin-top: 10px;
  margin-bottom: 10px;
}

.memoryHst {
  background-color: yellow;
}

.hstTitleText {
  background-color: #e7e5e5;
  padding: 7px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  color: black;
}

.cell-container {
  padding: 5px;
}

.main-info {
  display: flex;
  align-items: center;
  gap: 10px;

  .llm-latest {
    background-color: #efefef;
    padding: 3px;
    border-radius: 5px;
    font-weight: bold;
    color: rgb(249, 51, 51);
  }

  .model-name {
    font-weight: bold;
    color: #2c3e50;
  }

  .update-user {
    font-size: 0.9em;
    color: #1a8446;
    font-weight: bold;
  }
  .trans-time {
    font-size: 0.9em;
    color: #7f8c8d;
  }
}

.feedbackText {
  margin-top: 5px;
  padding: 5px;
  border-left: 4px solid #3498db;
  max-height: 107px; /* 최대 높이 설정 */
  overflow: hidden; /* 넘치는 내용 숨기기 */
  text-overflow: ellipsis; /* 잘린 부분 ... 표시 */
  display: -webkit-box; /* 다중 줄 ellipsis를 위해 flexbox 사용 */
  -webkit-line-clamp: 5; /* 표시할 최대 줄 수 (100px에 맞게 조정) */
  -webkit-box-orient: vertical; /* 수직 방향으로 박스 정렬 */
  position: relative; /* 툴팁 위치 조정을 위해 필요할 수 있음 */
  white-space: pre-wrap; /* \n을 줄바꿈으로 인식 */
  strong {
    color: #2980b9;
  }
}
</style>
